import { ButtonLinkComponent } from "./ButtonLinkComponent";
import React from "react";

type TimelineProps = {
  timeFrame: string;
  companyName: string;
  workTitle: string;
  tasksList: string[];
  technoList?: string;
  logoLink?: string;
  withLogo: boolean;
  withLink: boolean;
};

export const TimelineComponent: React.FC<TimelineProps> = ({
  timeFrame,
  companyName,
  workTitle,
  tasksList,
  technoList,
  logoLink,
  withLogo,
  withLink,
}) => {
  let logoImg;

  if (withLogo) {
    logoImg = (
      <img
        className="object-fit z-0 h-[30px] md:h-[40px]"
        src={logoLink}
        alt={companyName}
      />
    );
  }

  return (
    <li className="mb-10 ml-4">
      <div className="absolute z-0 w-3 h-3 bg-[#ffbf91] rounded-full mt-1.5 -left-1.5 border border-white"></div>
      {logoImg}
      <time className="mb-1 text-sm font-normal font-glacial leading-none text-gray-400">
        {timeFrame}
      </time>
      <h3 className="text-base md:text-lg font-bold font-ubuntu text-gray-900">
        {workTitle}
      </h3>
      <h3 className="text-base md:text-lg mb-4 font-ubuntu font-bold text-gray-900">
        {companyName}
      </h3>
      {tasksList.map((task, i) => {
        return (
          <p className="text-sm md:text-base text-justify font-normal font-glacial text-gray-500">
            {task}
          </p>
        );
      })}
      <p className="mb-4 mt-4 text-sm md:text-base text-justify font-glacial font-normal text-gray-500">
        {technoList}
      </p>
      {withLink ? (
        <ButtonLinkComponent
          hrefValue="contact"
          textToDisplay="Me contacter"
          withArrow={true}
        />
      ) : null}
    </li>
  );
};
