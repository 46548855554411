import { NavLink } from "react-router-dom";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import React,{ useState } from "react";


export const NavigationBar = () => {
  const [sideBar, setSideBar] = useState(false);

  const handleSideBar = () => {
    setSideBar(!sideBar);
  };

  return (
    <div className="w-full md:flex md:justify-between">
      <div className="md:ml-auto">
        <nav className="hidden md:flex">
          <NavLink
            className="ml-auto p-2 text-xl text-white font-ubuntu hover:bg-[#ff8229] focus:outline-none focus:ring-primary-300"
            style={({ isActive }) => ({
              fontWeight: isActive ? "bold" : "normal",
            })}
            to="/"
          >
            Accueil
          </NavLink>
          <NavLink
            className="p-2 text-xl text-white font-ubuntu hover:bg-[#ff8229] focus:outline-none focus:ring-primary-300"
            style={({ isActive }) => ({
              fontWeight: isActive ? "bold" : "normal",
            })}
            to="/about"
          >
            À propos
          </NavLink>
          <NavLink
            className="p-2 text-xl text-white font-ubuntu hover:bg-[#ff8229] focus:outline-none focus:ring-primary-300"
            style={({ isActive }) => ({
              fontWeight: isActive ? "bold" : "normal",
            })}
            to="/services"
          >
            Travailler avec moi
          </NavLink>
          <NavLink
            className="p-2 text-xl text-white font-ubuntu hover:bg-[#ff8229] focus:outline-none focus:ring-primary-300"
            style={({ isActive }) => ({
              fontWeight: isActive ? "bold" : "normal",
            })}
            to="/contact"
          >
            Contact
          </NavLink>
        </nav>
      </div>
      <div
        onClick={handleSideBar}
        className="block px-4 md:hidden hover:cursor-pointer"
      >
        <AiOutlineMenu size={30} />
      </div>
      <div
        className={
          sideBar
            ? "fixed z-10 left-0 top-0 w-[40%] h-full bg-[#f97316] shadow-lg ease-in-out duration-300"
            : "fixed z-0 left-[-100%]"
        }
      >
        <div
          onClick={handleSideBar}
          className="absolute right-4 top-4 hover:cursor-pointer"
        >
          <AiOutlineClose size={30} />
        </div>

        <ul className="pt-[50px]">
          <a onClick={handleSideBar} href="/">
            <li className="p-4 border-b border-b-white hover:bg-[#ff8229] focus:outline-none focus:ring-primary-300">
              <NavLink
                className="text-lg md:text-xl text-white font-ubuntu"
                style={({ isActive }) => ({
                  fontWeight: isActive ? "bold" : "normal",
                })}
                to="/"
              >
                Accueil
              </NavLink>
            </li>
          </a>
          <a onClick={handleSideBar} href="/about">
            <li className="p-4 border-b border-b-white hover:bg-[#ff8229] focus:outline-none focus:ring-primary-300">
              <NavLink
                className="text-lg md:text-xl text-white font-ubuntu"
                style={({ isActive }) => ({
                  fontWeight: isActive ? "bold" : "normal",
                })}
                to="/about"
              >
                À propos
              </NavLink>
            </li>
          </a>
          <a onClick={handleSideBar} href="/services">
            <li className="p-4 border-b border-b-white hover:bg-[#ff8229] focus:outline-none focus:ring-primary-300">
              <NavLink
                className="text-lg md:text-xl text-white font-ubuntu"
                style={({ isActive }) => ({
                  fontWeight: isActive ? "bold" : "normal",
                })}
                to="/services"
              >
                Travailler avec moi
              </NavLink>
            </li>
          </a>
          <a onClick={handleSideBar} href="/contact">
            <li className="p-4 border-b border-b-white hover:bg-[#ff8229] focus:outline-none focus:ring-primary-300">
              <NavLink
                className="text-lg md:text-xl text-white font-ubuntu"
                style={({ isActive }) => ({
                  fontWeight: isActive ? "bold" : "normal",
                })}
                to="/contact"
              >
                Contact
              </NavLink>
            </li>
          </a>
        </ul>
      </div>
    </div>
  );
};
