import React from 'react';

type RightTileProps = {
  contentText: string;
  titleText: string;
  iconLocation?: string;
};

export const RightGridTileComponent: React.FC<RightTileProps> = ({
  contentText,
  titleText,
  iconLocation,
}) => {
  let icon;

  if (iconLocation !== null && iconLocation !== undefined) {
    icon = (
      <img
        className="p-2 place-self-center object-fit"
        src={iconLocation}
        alt="Icône tuile"
      />
    );
  }

  return (
    <div className="bg-[#ffbf91]">
      <div className="py-10 mx-auto grid grid-cols-[70%_30%] h-auto rectangle items-center max-w-full md:max-w-screen-md lg:max-w-screen-lg">
        <div className="grid grid-rows-2">
          <h3 className="p-2 break-words text-lg md:text-xl font-bold font-ubuntu place-self-center">
            {titleText}
          </h3>
          <p className="p-2 text-base md:text-lg text-justify font-glacial place-self-center">
            {contentText}
          </p>
        </div>
        {icon}
      </div>
    </div>
  );
};
