import React from "react";

type TitleProps = {
  text: string;
};

export const Title: React.FC<TitleProps> = ({ text }) => {
  return (
    <div>
      <h1 className="text-2xl md:text-4xl font-glacial font-extrabold mb-5 tracking-tight text-center underline underline-offset-[4px] md:underline-offset-[15px] decoration-[#ffbf91]">
        {text}
      </h1>
    </div>
  );
};
