import { NavigationBar } from "./NavigationBar";
import React from 'react';

export const HeaderComponent = () => {
  return (
    <header className="max-w-auto bg-[#f97316]">
      <div className="hidden md:block absolute z-0 p-6">
        <img
          className="w-[160px] rounded-full shadow"
          src="images/photo-perso.jpeg"
          alt="Denis Ramplou"
        />
      </div>
      <div className="text-center py-2">
        <a href="/">
          <img
            className="w-[170px] md:w-[230px] mx-auto"
            src="images/logo-header.png"
            alt="Denis Ramplou"
          />
        </a>
        <p className="text-lg md:text-xl text-white font-ubuntu font-bold align-middle">
          Développeur freelance à votre (micro)-service ! 
        </p>
      </div>
      <NavigationBar />
    </header>
  );
};
