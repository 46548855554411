import { NavLink } from "react-router-dom";
import { Title } from "../components/Title";
import React from "react";
import { Helmet } from "react-helmet-async";

export const NotFound = () => {
  return (
    <div className="px-2 mx-auto max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg py-16">
      <Helmet>
        <title>Denis Ramplou - 404 Page Inexistante</title>
        <meta name="prerender-status-code" content="404"></meta>
      </Helmet>
        <div className="py-5">
            <Title text="Eh voici une erreur 404 : NotFound !" />
            <div className="pt-16 text-center">
            <p className="py-2 text-xl md:text-2xl font-glacial"> Oups, on dirait que la page que tu cherches n'existe pas !</p>
            </div>
            <div className="py-16 text-center">
                <p className="text-xl md:text-2xl font-glacial">Tu t'es peut-être perdu, voici quelques lien utiles pour toi :</p>
                <ul>
                    <li><NavLink className="py-2 text-base md:text-lg font-glacial hover:font-bold hover:underline" to="/">Accueil</NavLink></li>
                    <li><NavLink className="py-2 text-base md:text-lg font-glacial hover:font-bold hover:underline" to="/about">À propos</NavLink></li>
                    <li><NavLink className="py-2 text-lg md:text-lg font-glacial hover:font-bold hover:underline" to="/services">Travailler avec moi</NavLink></li>
                    <li><NavLink className="py-2 text-lg md:text-lg font-glacial hover:font-bold hover:underline" to="/contact">Contact</NavLink></li>
                </ul>
            </div>
      </div>
    </div>
  );
};
