import React from 'react';

type ButtonLinkProps = {
  hrefValue: string;
  textToDisplay: string;
  withArrow: boolean;
};

export const ButtonLinkComponent: React.FC<ButtonLinkProps> = ({
  hrefValue,
  textToDisplay,
  withArrow,
}) => {
  let arrow;

  if (withArrow) {
    arrow = (
      <svg
        className="w-3 h-3 ml-2"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
    );
  }

  return (
    <a
      href={hrefValue}
      className="inline-flex items-center py-3 px-5 text-medium font-bold font-glacial text-center text-white rounded-lg bg-[#ea8e4d] sm:w-fit hover:bg-[#ff8229] focus:ring-4 focus:outline-none focus:ring-primary-300"
    >
      {textToDisplay} {arrow}
    </a>
  );
};
