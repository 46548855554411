import React, { useState } from "react";

type ToastBarProps = {
  displayText: string;
};

export const ToastBar: React.FC<ToastBarProps> = ({ displayText }) => {
  const [toastSimple, setToastSimple] = useState(false);

  const handleClose = () => {
    console.log(!toastSimple);
    setToastSimple(!toastSimple);
  };

  const showToast =
    "fixed bottom-8 right-0 m-8 flex items-center w-full max-w-xs p-4 space-x-4 text-green-600 bg-green-300 divide-x divide-green-300 rounded-lg shadow ease-in-out duration-1000";

  const closeToast =
    "fixed invisible flex items-center w-full max-w-xs p-4 space-x-4 text-green-600 bg-green-300 divide-x divide-green-300 rounded-lg shadow ease-in-out duration-1000";

  return (
    <div className={!toastSimple ? showToast : closeToast} role="alert">
      <svg
        aria-hidden="true"
        className="w-5 h-5 text-green-600"
        focusable="false"
        data-prefix="fas"
        data-icon="paper-plane"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M511.6 36.86l-64 415.1c-1.5 9.734-7.375 18.22-15.97 23.05c-4.844 2.719-10.27 4.097-15.68 4.097c-4.188 0-8.319-.8154-12.29-2.472l-122.6-51.1l-50.86 76.29C226.3 508.5 219.8 512 212.8 512C201.3 512 192 502.7 192 491.2v-96.18c0-7.115 2.372-14.03 6.742-19.64L416 96l-293.7 264.3L19.69 317.5C8.438 312.8 .8125 302.2 .0625 289.1s5.469-23.72 16.06-29.77l448-255.1c10.69-6.109 23.88-5.547 34 1.406S513.5 24.72 511.6 36.86z"
        ></path>
      </svg>
      <div className="pl-4 text-lg font-glacial font-bold">{displayText}</div>

      <button
        className="ml-auto -mx-1.5 -my-1.5 bg-green-300 text-green-600 rounded-lg focus:ring-green-400 p-1.5 hover:bg-green-500 inline-flex h-8 w-8"
        onClick={handleClose}
        aria-label="Close"
      >
        <span className="sr-only">Close</span>
        <svg
          aria-hidden="true"
          className="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>
    </div>
  );
};
