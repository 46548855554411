import { Title } from "../components/Title";
import { SkillComponent } from "../components/SkillComponent";
import { TimelineComponent } from "../components/TimelineComponent";
import { ButtonLinkComponent } from "../components/ButtonLinkComponent";
import React from "react";

export const About = () => {
  const capgeminiTasksArr = [
    "Tâches et responsabilités : ",
    "- Réalisation, qualification et certification d’évolutions de sujets existants,",
    "- MCO: Analyse et résolution d’anomalies de production,",
    "- Référent technique,",
    "- Réalisation, qualification et livraisons de projets de build,",
    "- Transfert de connaissances technique et fonctionnel.",
  ];
  const pictoTasksArr = [
    "Lead développeur Backend :",
    "- Conception, développement et déploiement sur les évolutions de la partie data et du reporting en collaboration directe avec le CTO,",
    "- Développement de la partie Backend/API des produits,",
    "- Conseil lors des développements de normes/standards d’accessibilité (Afnor, CEN, CEREMA),",
    "- Administration des applicatifs internes.",
    "Data Protection Officer :",
    "- Conception, Mise en place, Pilotage des équipes internes au Privacy By Design dans le cadre législatif Européen,",
    "- Animation de sensibilisations à la Sécurité du SI.",
  ];
  const stmaclouTasksArr = [
    "Tâches et responsabilités : ",
    "– Conseil sur la conception & transition entre flux BI (Stambia) vers des API Node.js sur GCP,",
    "- Conseil et mise en place l‘architecture Cloud API,",
    "- Soutien en formation en développement d‘API Node.js,",
    "- Référent pilotage technique sur le périmètre Commerce (magasins), RH & Finance,",
    "- Pilotage de projet d‘évolutions en OptIn magasins.",
  ];

  return (
    <div className="px-2 mx-auto max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg py-16">
      <Title text="À propos" />
      <div className="py-5">
        <h2 className="text-xl md:text-2xl font-ubuntu font-bold">
          Qui suis-je ?
        </h2>
        <p className="text-base md:text-lg font-glacial pt-5 pb-2 text-justify">
          Originaire d'Arras, j'ai découvert l'informatique avec mon frère
          lorsque nous étions jeunes.
          <br />
          J'ai grandi dans la campagne artésienne, autant te dire que l'accès à
          la ville n'était pas chose aisée... J'ai vite vu en l'outil
          informatique un passe-temps, un moyen de communication et de création,
          partie intégrante de ma vie depuis mon adolescence !
        </p>
        <p className="text-base md:text-lg font-glacial py-2 text-justify">
          J'ai fait la formation Bac+5 (niveau 1 RNCP) au centre Exia.CESI
          d'Arras en spécialité développement applicatif.
        </p>
        <p className="text-base md:text-lg font-glacial py-2 text-justify">
          Ça fait maintenant 8 ans que je développe des solutions API et models
          de données pour plusieurs comptes. Que ce soit de grandes entreprises
          comme des structures plus modestes ou même de la start-up, je m'adapte
          rapidement à l'entreprise et t'aiderai à mettre en place les
          meilleures solutions en fonction de ton besoin.
        </p>
      </div>
      <div>
        <h2 className="text-xl md:text-2xl font-ubuntu font-bold">
          Expériences professionnelles
        </h2>
        <div className="py-5">
          <ol className="relative z-0 border-l border-gray-700">
            <TimelineComponent
              timeFrame="Février 2015 à Octobre 2017"
              companyName="Capgemini"
              workTitle="Ingénieur Business Intelligence"
              tasksList={capgeminiTasksArr}
              technoList="Environnement technique : Teradata 14.10, SQL, Korn Shell, Lean Management, Jenkins."
              logoLink="logos/entreprise-logos/capgemini-logo.png"
              withLogo={true}
              withLink={false}
            />

            <TimelineComponent
              timeFrame="Octobre 2017 à Février 2022"
              companyName="Unis C - Picto Access"
              workTitle="Lead développeur Backend & DPO"
              tasksList={pictoTasksArr}
              technoList="Environnement technique : Ruby on Rails, PostgreSQL/PostGIS, GraphQL, MongoDB, Docker & Kubernetes, Grafana, Git, Gitlab CI/CD, AWS & GCP, Debian."
              logoLink="logos/entreprise-logos/picto-access-logo.png"
              withLogo={true}
              withLink={false}
            />

            <TimelineComponent
              timeFrame="Février 2022 à Août 2022"
              companyName="Tapis Saint-Maclou"
              workTitle="Chef de projet technique Data"
              tasksList={stmaclouTasksArr}
              technoList="Environnement technique : Windows Server 2008 R2 & 2012, Microsoft SQL Server 2008 R2, GCP, Gitlab, Vault, Terraform, Node.js (ES6), Traefik, Grafana."
              logoLink="logos/entreprise-logos/tapis-saint-maclou-logo.png"
              withLogo={true}
              withLink={false}
            />

            <TimelineComponent
              timeFrame="Aujourd'hui"
              companyName="Chez toi ! 😉"
              workTitle="Freelance Développeur Web Backend"
              tasksList={["Répondre à tes ambitions grâce à mes compétences"]}
              withLogo={false}
              withLink={true}
            />
          </ol>
        </div>
      </div>
      <div>
        <h2 className="text-xl md:text-2xl font-ubuntu font-bold">
          Mes compétences
        </h2>
        <div className="py-5">
          <p className="text-lg md:text-xl font-glacial font-semibold">
            Langages & API
          </p>
          <div className="grid grid-cols-2 gap-4 py-4">
            <SkillComponent
              image="logos/techno-logos/ror-logo.png"
              skillName="Ruby on Rails"
            />
            <SkillComponent
              image="logos/techno-logos/ruby-logo.png"
              skillName="Ruby"
            />
            <SkillComponent
              image="logos/techno-logos/nodejs-logo.png"
              skillName="Node.js"
            />
            <SkillComponent
              image="logos/techno-logos/restapi-logo.png"
              skillName="Rest API"
            />
            <SkillComponent
              image="logos/techno-logos/graphql-logo.png"
              skillName="GraphQL"
            />
            <SkillComponent
              image="logos/techno-logos/swagger-logo.png"
              skillName="Swagger"
            />
            <SkillComponent
              image="logos/techno-logos/terraform-logo.png"
              skillName="Terraform"
            />
          </div>
        </div>
        <div className="py-2">
          <p className="text-lg md:text-xl font-glacial font-semibold">
            Stockages de données
          </p>
          <div className="grid grid-cols-2 gap-4 py-4">
            <SkillComponent
              image="logos/techno-logos/postgresql-logo.png"
              skillName="PostgreSQL"
            />
            <SkillComponent
              image="logos/techno-logos/zfs-logo.png"
              skillName="ZFS"
            />
            <SkillComponent
              image="logos/techno-logos/mongodb-vertical-logo.png"
              skillName="MongoDB"
            />
            <SkillComponent
              image="logos/techno-logos/influxdb-logo.png"
              skillName="InfluxDB"
            />
            <SkillComponent
              image="logos/techno-logos/mysql-logo.png"
              skillName="MySQL et MariaDB"
            />
          </div>
        </div>
        <div className="py-2">
          <p className="text-lg md:text-xl font-glacial font-semibold">
            Plateformes Cloud & outils OPS
          </p>
          <div className="grid grid-cols-2 gap-4 py-4">
            <SkillComponent
              image="logos/techno-logos/gcp-logo.png"
              skillName="Google Cloud Platform"
            />
            <SkillComponent
              image="logos/techno-logos/aws-logo.png"
              skillName="Amazon Web Services"
            />
            <SkillComponent
              image="logos/techno-logos/gitlab-logo.png"
              skillName="Gitlab CI / CD"
            />
            <SkillComponent
              image="logos/techno-logos/docker-logo.png"
              skillName="Docker / Docker Compose"
            />
            <SkillComponent
              image="logos/techno-logos/kubernetes-logo.png"
              skillName="Kubernetes"
            />
          </div>
        </div>
        <div className="py-2">
          <p className="text-lg md:text-xl font-glacial font-semibold">
            Outils & Agilité
          </p>
          <div className="grid grid-cols-2 gap-4 py-4">
            <SkillComponent
              image="logos/techno-logos/grafana-logo.png"
              skillName="Grafana"
            />
            <SkillComponent
              image="logos/techno-logos/git-logo.png"
              skillName="Git"
            />
            <SkillComponent
              image="logos/techno-logos/asana-logo.png"
              skillName="Asana"
            />
            <SkillComponent
              image="logos/techno-logos/jira-logo.png"
              skillName="Jira"
            />
          </div>
        </div>
        <div className="py-5">
          <ButtonLinkComponent
            hrefValue="services"
            textToDisplay="Travailles avec moi !"
            withArrow={true}
          />
        </div>
      </div>
    </div>
  );
};
