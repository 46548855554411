import React from "react";

type SkillProps = {
  image: string;
  skillName: string;
};

export const SkillComponent: React.FC<SkillProps> = ({ image, skillName }) => {
  return (
    <div className="grid grid-cols-[20%_80%] bg-white border border-gray-200 rounded-lg shadow">
      <img
        className="object-scale-down h-[50px] place-self-center p-2"
        src={image}
        alt={skillName}
      />
      <p className="p-2 text-base md:text-lg break-words lg:text-xl font-glacial tracking-tight text-black self-center">
        {skillName}
      </p>
    </div>
  );
};
