import { Title } from "../components/Title";
import { LeftGridTileComponent } from "../components/LeftGridTileComponent";
import { RightGridTileComponent } from "../components/RightGridTileComponent";
import { ButtonLinkComponent } from "../components/ButtonLinkComponent";
import { SubTitle } from "../components/SubTitle";
import React from "react";

export const Services = () => {
  return (
    <div className="max-w-full">
      <div className="px-2 pt-16 mx-auto max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg">
        <Title text="Travailler avec moi !" />

        <div className="py-5 text-justify">
          <h2 className="text-lg md:text-2xl font-ubuntu font-bold">
            Mise en situation
          </h2>
          <p className="py-2 text-base md:text-lg font-glacial">
            Laisse-moi deviner, tu en as marre que l'on te propose des
            compétences d'expert alors que tu récupères un junior ?
          </p>
          <p className="py-2 text-base md:text-lg font-glacial">
            Tu as un besoin crucial de compétences pour venir à bout de ce
            projet ? Tu as besoin de pouvoir lui dire "On m'a dit que je devais
            être plus présent sur le Web, plus rapide... Mais j'avoue que je
            suis perdu moi dans tout ce mic mac d'API, de flux ETL, de
            dashboard, de monitoring, de streaming... Je pensais que c'était
            Netflix moi ça !" et qu'on te propose des vraies solutions ?!
          </p>
          <p className="py-2 text-base md:text-lg font-glacial">
            Ne cherche plus, <b>tu es au bon endroit !</b>
          </p>
          <p className="py-2 text-base md:text-lg font-glacial">
            Laisse-moi encore une fois deviner, l'automatisation ça t'intéresse
            en plus ? Plus envie d'avoir à attendre le Jeudi après le comité de
            déploiement pour voir le fruit de ton travail en production ?
          </p>
          <p className="py-2 text-base md:text-lg font-glacial">
            Heureusement pour toi, je peux t'aider à mettre en place tout cela,
            je peux également te conseiller sur des outils à la pointe pour que
            tu gagnes en productivité sans te soucier de ton parc applicatif !
          </p>
        </div>

        <div className="py-5 text-justify">
          <SubTitle text="Mon Credo" />
          <p className="py-2 text-base md:text-lg font-glacial">
            Je crois dur comme fer qu'on peut aller loin avec un modèle de
            données simple et propre ainsi qu'une architecture d'API saine et
            efficace donnera toujours de bons résultats et une meilleure
            productivité !<br />
          </p>
          <p className="py-2 text-base md:text-lg font-glacial">
            Via mon expertise en développement d'API Backend, en automatisation
            de livraisons, c'est là où je saurais te prêter main forte pour
            améliorer la productivité des équipes et réduire tes coûts !<br />
            Les <b>API REST</b> sont un bon pied à l'étrier pour une transition
            progressive et maîtrisée. Mais à mon humble avis, il faut maintenant
            tendre vers du <b>GraphQL</b> qui sera d'autant plus flexible pour
            permettre une conception de nouveaux services et produits accélérée.
          </p>
          <p className="py-2 text-base md:text-lg font-glacial">
            Quoi de mieux en automatisation que de laisser une API faire le
            travail pour vous ?<br />
            On ouvre un ensemble de données <b>simples</b>, <b>construites</b>{" "}
            en consensus avec le métier, <b>compréhensibles</b> par tous, et{" "}
            <b>bien documentées</b>. On saupoudre le tout de fonctionnalités de
            bases (et non de poudre de perlinpainpain !) et déjà 80% des projets
            qui en ont besoin pourront se servir librement d'un catalogue
            pré-construit, accessible et réutilisable.
          </p>
          <p className="py-2 text-base md:text-lg font-glacial">
            En start-up, tu sais, combler une dette technique ?! C'était mon
            pain quotidien :<br />
            - Faire du refactor de fonction,
            <br />
            - Automatiser une tâche avec peu de moyens,
            <br />
            - Développer des Rake Tasks avec Ruby ou des commandes systèmes,
            <br />
            - Paramétrer des Cron job en Bash,
            <br />
            - Ajouter de l'intelligence à une API existante,
            <br />
            - Créer et automatiser les cas de tests manquants,
            <br />
            - Factoriser, Factoriser, Factoriser !<br />
          </p>
          <p className="py-2 text-base md:text-lg font-glacial">
            Pour moi un algorithme bien pensé c'est cool et gratifiant, mais
            s'il n'est compréhensible que par celui qui l'a écrit, ça ne sert au
            final pas à grand chose ! J'ai trop souvent vu des API construites
            trop vite, d'une complexité sans nom, sans nomenclature ni
            commentaires... Résultat, un code absolument indigeste où personne
            ne veut mettre les mains dedans : Ça créée de la dette technique, du
            "code jetable" et donc de l'argent par les fenêtres !<br />
            Alors sois écolo et fais-toi accompagner par des personnes
            compétentes en la matière !
          </p>
          <p className="py-2 text-base md:text-lg font-glacial">
            Si ce que je crois t'as parlé, alors jette un coup d'oeil plus bas
            et{" "}
            <b>
              <a href="/contact">fais-moi signe</a>
            </b>
            , je serais très heureux d'échanger avec toi !
          </p>
        </div>
        <div className="py-5">
          <SubTitle text="Mon offre de services" />
        </div>
      </div>
      <div>
        <div className="grid">
          <LeftGridTileComponent
            titleText="Automatisation de delivery"
            contentText="L'agilité et l'amélioration continue, ça te parle ? Pour être le plus agile possible, s'équiper pour automatiser les tâches redondantes et instaurer les pratiques d'intégration continue est primordial !"
            iconLocation="icons/devops-black-ico.png"
          />
          <RightGridTileComponent
            titleText="Hébergement dans le Cloud"
            contentText="Vos API ont besoin d'être accessibles 24h/24, 7j/7 et ce 100% du temps. Le Cloud reste un fer de lance dans la disponibilité de vos outils. Mettre en place une infrastructure Cloud adaptée à vos besoins !"
            iconLocation="icons/cloud-black-ico.png"
          />
          <LeftGridTileComponent
            titleText="Développement API Backend"
            contentText="En tant que développeur d'API, je peux intervenir sur plusieurs technologies, paradygmes API (REST, GraphQL, ...), je propose également mes services en réalisation et accompagnements de réalisation"
            iconLocation="icons/dev-black-ico.png"
          />
          <RightGridTileComponent
            titleText="Conception d'un catalogue d'API métiers"
            contentText="Concevoir des structures de données ouvertes et adaptées aux besoins du parc applicatif en place ou à venir, c'est aussi ça un catalogue API efficace !"
            iconLocation="icons/database-black-ico.png"
          />
          <LeftGridTileComponent
            titleText="Privacy By Design"
            contentText="En tant qu'ancien DPO, je suis très sensible au respect de la vie privée sur les applications Web & Mobile. Je ferais donc très attention à suivre les recommandations en matière de vie privée et RGPD."
            iconLocation="icons/privacy-ico.png"
          />
          <RightGridTileComponent
            titleText="A distance ou sur site"
            contentText="Sur la région lilloise ou en distanciel, qu'importent les kilomètres qui nous sépare... Je répondrai à tes attentes !"
            iconLocation="icons/globe-ico.png"
          />
          <LeftGridTileComponent
            titleText="Demande ton devis"
            contentText="Un prix transparent avec une proposition sur devis"
            iconLocation="icons/money-ico.png"
          />
        </div>
      </div>

      <div className="px-2 pt-5 pb-16 mx-auto max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg">
        <div className="py-5 text-justify">
          <SubTitle text="Pourquoi moi et pas un autre ?" />

          <p className="pt-2 pb-5 text-base md:text-lg font-glacial">
            - Un développeur avec plus de 8 ans d’expérience sur des
            technologies Backend & Data.
            <br />
            - De l’aide dans ta transition digitale avec des outils de pointe.
            <br />
            - Une dose de bonne humeur.
            <br />
            - Un « tech » curieux, humain, pas avare de partage et d’échange.
            <br />
            - Des solutions innovantes adaptées à tes besoins et enjeux.
            <br />- Ton projet, ma mission.
          </p>
          <ButtonLinkComponent
            hrefValue="contact"
            textToDisplay="Me contacter"
            withArrow={true}
          />
        </div>
      </div>
    </div>
  );
};
