import { FaMedium, FaGithubSquare, FaLinkedin, FaDev } from "react-icons/fa";
import React from 'react';

export const FooterComponent = () => {
  return (
    <footer className="bg-[#ffbf91]">
      <div className="p-2 mx-auto max-w-[1240px]">
        <div className="py-4 grid lg:grid-cols-3 gap-8">
          <div>
            <h3 className="text-xl lg:text-2xl font-bold font-ubuntu">
            Denis Ramplou 💻 Développeur backend freelance
            </h3>
            <p className="py-4 text-base md:text-lg font-glacial">
              Merci d'avoir pris le temps de visiter mon site. N'hésite pas à
              m'envoyer un mail via la{" "}
              <a
                href="contact"
                className="underline text-lg font-glacial hover:font-bold"
              >
                page de contact
              </a>{" "}
              ou via mes réseaux sociaux ! Dis-moi en quoi je peux t'aider, ce
              que tu as pensé de mon site ou simplement fais-moi un coucou 👋
            </p>
            <div className="flex justify-between md:w-[75%] my-6">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://medium.com/@denis.ramplou"
              >
                <FaMedium size={30} className="hover:bg-[white]" /> 
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/in/ramplou-denis-32024a68/"
              >
                <FaLinkedin size={30} className="hover:bg-[white]" />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://github.com/D0ud0v"
              >
                <FaGithubSquare size={30} className="hover:bg-[white]" />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://dev.to/dramp16"
              >
                <FaDev size={30} className="hover:bg-[white]" />
              </a>
            </div>
          </div>
          <div>
            <h6 className="text-xl lg:text-2xl font-bold font-ubuntu">
              A Propos
            </h6>
            <ul className="py-4 grid gap-2 ">
              <li className="text-base md:text-lg">
                <a
                  className="font-glacial hover:underline hover:font-bold"
                  href="contact"
                >
                  Contact
                </a>
              </li>
              <li className="text-base md:text-lg">
                <a
                  className="font-glacial hover:underline hover:font-bold"
                  href="legal-informations"
                >
                  Informations légales
                </a>
              </li>
              <li className="text-base md:text-lg">
                <a
                  className="font-glacial hover:underline hover:font-bold"
                  href="privacy"
                >
                  Vie privée
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h6 className="w-full text-xl lg:text-2xl font-bold font-ubuntu">
              Contact
            </h6>
            <p className="py-4 text-base md:text-lg font-glacial">
              113 Rue des Stations
              <br />
              59800 Lille
            </p>

            <a
              className="text-base md:text-lg font-glacial underline"
              href="mailto:denis.ramplou@gmail.com"
            >
              denis.ramplou@gmail.com
            </a>
            <br />
            <a
              className="text-base md:text-lg font-glacial underline"
              href="tel:+33649819013"
            >
              +33 (0)6.49.81.90.13
            </a>
          </div>
        </div>
        <div>
          <p className="py-4 text-sm md:text-base lg:text-lg font-glacial">
            Created by Denis Ramplou. © ALL RIGHTS RESERVED.
          </p>
        </div>
      </div>
    </footer>
  );
};
