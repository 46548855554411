import { useForm, SubmitHandler } from "react-hook-form";
import emailjs from "@emailjs/browser";
import { Title } from "../components/Title";
import { ToastBar } from "../components/ToastBar";
import React from "react";

type FormInputs = {
  subject: string;
  email: string;
  content: string;
  name: string;
  consent: boolean;
};

export const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitSuccessful, errors },
  } = useForm<FormInputs>();

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    if (process.env.REACT_APP_ENV === "production") {
      emailjs
        .send(
          `${process.env.REACT_APP_EMAILJS_SEND_SERVICE_ID}`,
          `${process.env.REACT_APP_EMAILJS_TEMPLATE_ID}`,
          data,
          `${process.env.REACT_APP_EMAILJS_API_KEY}`
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
    } else {
      console.log(data);
    }
  };

  return (
    <section className="mx-auto px-2 py-16 max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg">
      <div>
        <Title text="Me contacter" />
        <div className="py-5">
          <p className="mb-5 lg:mb-5 font-light font-glacial text-center text-gray-500 sm:text-xl">
            Tu veux prendre contact pour que je t'aide à concrétiser tes projets
            ou simplement m'envoyer des petits mots doux ?
          </p>
          <p className="mb-8 lg:mb-16 font-light font-glacial text-center text-gray-500 sm:text-xl">
            Chouette j'ai hâte d'entendre parler de toi !
          </p>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
            <div>
              <label
                htmlFor="name"
                className="block mb-2 text-lg font-glacial font-bold text-gray-900"
              >
                Qui es-tu ?
              </label>
              <input
                {...register("name")}
                type="text"
                id="email"
                className="shadow-sm font-glacial bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                placeholder="Steve Travaux"
                required
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-lg font-glacial font-bold text-gray-900"
              >
                Ton adresse mail
              </label>
              <input
                {...register("email")}
                type="email"
                id="email"
                className="shadow-sm font-glacial bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                placeholder="genialissime.adresse-mail@example.com"
                required
              />
            </div>
            <div>
              <label
                htmlFor="subject"
                className="block mb-2 text-lg font-glacial font-bold text-gray-900"
              >
                C'est à quel sujet ?
              </label>
              <input
                {...register("subject")}
                type="text"
                id="subject"
                className="block p-3 w-full text-lg font-glacial text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500"
                placeholder="Dis moi pour quelle raison tu me contactes !"
                required
              />
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="content"
                className="block mb-2 text-lg font-glacial font-bold text-gray-900"
              >
                Contenu
              </label>
              <textarea
                {...register("content")}
                id="content"
                rows={6}
                className="block p-2.5 w-full text-lg font-glacial text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                placeholder="Ton petit mot doux"
              ></textarea>
            </div>
            <div>
              <input
                {...register("consent")}
                type="checkbox"
                id="consent"
                name="consent"
                required
              />
              <label
                htmlFor="consent"
                className="pl-1 font-light text-lg font-glacial text-center text-gray-500"
              >
                En cochant cette case, vous consentez à l'utilisation de votre
                adresse mail à des fins détaillées sur la page 
                <a
                  href="/privacy"
                  className="underline underline-offset-2 text-black hover:font-bold"
                >
                  Vie Privée
                </a>
                .
              </label>
            </div>

            {errors.email && (
              <p className="text-[red] font-glacial">Email requis</p>
            )}

            <button
              type="submit"
              className="py-3 px-5 text-lg font-bold font-glacial text-center text-white rounded-lg bg-[#ea8e4d] sm:w-fit hover:bg-[#ff8229] focus:ring-4 focus:outline-none focus:ring-primary-300"
            >
              Envoyer
            </button>
            {isSubmitSuccessful && (
              <ToastBar displayText="Message envoyé avec succès !" />
            )}
          </form>
        </div>
      </div>
    </section>
  );
};
