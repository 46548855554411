import React from "react";
import { Title } from "../components/Title";

export const Privacy = () => {
  return (
    <div className="mx-auto px-2 py-16 max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg">
      <div className="center">
        <Title text="Vie Privée" />

        <div className="py-16 font-glacial text-lg text-justify">
          <p>
            Anciennement DPO, je suis conscient de l'enjeu de la vie privée sur
            Internet et notamment de la collecte d'informations personnelles.
          </p>
          <br />
          <p>
            Soyez rassurés, ce site n'a de vocation qu'être un CV en ligne avec
            quelques légères améliorations telles que de la prise de contact.
            Dans l'optique de réaliser une prise de contact.
          </p>
          <p>Il implémente cependant un service d'envoi d'email externalisé.</p>

          <h3 className="py-5 text-xl font-glacial font-bold">
            1. Identité du responsable du traitement
          </h3>
          <p>Les données personnelles sont collectées par :</p>

          <p>
            <b>Denis Ramplou EI</b>
            <br />
            113 rue des Stations
            <br />
            59800 Lille
          </p>

          <h3 className="py-5 text-xl font-glacial font-bold">
            2. Les traitements relatifs à vos données personnelles
          </h3>
          <p>
            En visitant et/ou en utilisant le service proposé d'envoi d'email
            sur mon site www.denis-ramplou.fr vous acceptez la collecte,
            l’utilisation et le transfert de vos données personnelles dans les
            limites et le cadre définis ci-après.
          </p>

          <h4 className="py-4 text-lg font-glacial font-bold">
            Quelles données et comment sont-elles collectées ?
          </h4>
          <p>
            Dans l'optique de réaliser l'envoi d'un email, les données
            collectées sont renseignées par vous-même lors du remplissage des
            champs textuels prévus à cet effet sur la page de contact.
            <br />
            Les données personnelles collectées sont donc les suivantes :<br />
            - Nom et Prénom OU Raison sociale <br />
            - Adresse email <br />
          </p>
          <div className="pt-32">
            <Title text="WORK IN PROGRESS 🛠️" />
          </div>
        </div>
      </div>
    </div>
  );
};
