import { Title } from "../components/Title";
import React from "react";
import { SubTitle } from "../components/SubTitle";

export const Legal = () => {
  return (
    <div className="mx-auto px-2 py-16 max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg">
      <div className="center">
        <Title text="Informations légales" />

        <SubTitle text='Denis Ramplou - EI'/>
        <p className="py-2 text-base md:text-lg font-glacial">
          Entreprise Individuelle - Régime Micro Entreprise
          <b>Siège social :</b> <br/>113 Rue des Stations, Appartement RDC<br/> 59800 Lille<br/><br/>
          <b>SIREN :</b> 951 076 025<br/>
          <b>SIRET :</b> 951 076 025 00018<br/>
          <b>Responsable :</b> Denis RAMPLOU<br/>
          <b>Contact :</b> denis.ramplou@gmail.com<br/>
        </p>
        
        <SubTitle text='Hébergement' />
        <p className="py-2 text-base md:text-lg font-glacial">
          <b>VERCEL</b><br/><br/>

          Vercel Inc.<br/>
          340 S Lemon Ave #4133<br/>
          Walnut, CA 91789<br/>
          <a href="https://vercel.com">https://vercel.com</a>
        </p>
        </div>
    </div>
  );
};
