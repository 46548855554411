import { SubTitle } from "../components/SubTitle";
import { Title } from "../components/Title";
import React from "react";

export const Home = () => {
  return (
    <div className="px-2 pt-32 mx-auto max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg">
      <div>
        <Title text="Hello ! Moi c'est Denis, bienvenue 👋" />
      </div>
      <div className="py-5 text-justify">
        <p className="pt-16 text-base md:text-xl text-justify text-gray-800 font-glacial">
          Développeur freelance vivant à Lille, passionné d'apprentissage et de
          l'informatique en général.
          <br />
          Anciennement start-upper et DPO j'adore
          partager mes expériences et mon savoir-faire !<br />
          En ce moment, mes technologies Backend de coeur sont : Node.js, Ruby
          on Rails, Docker et PostgreSQL (entre autres !)
        </p>
      </div>
      <div className="py-5 text-justify">
        <div className="pt-5">
          <SubTitle text="Pourquoi ce site?" />
        </div>
        <p className="pt-16 text-base md:text-xl text-justify text-gray-800 font-glacial">
          Bienvenue sur mon site web perso ! Pour moi il s'agit à la fois d'un
          CV, d'un espace créatif, d'apprentissage, mais aussi d'une plateforme
          de prise de contact pour qui souhaiterai travailler avec moi.
        </p>
        <br />
        <p className="text-base md:text-xl text-justify text-gray-800 font-glacial">
          Tout de A à Z est fait par mes soins en terme, de déploiement continu,
          de référencement, d'hébergement, de design et de contenu (clairement
          ça fait longtemps que je n'ai pas fait de FrontEnd hein ? 😂). Mais ça
          m'a permis de créer quelque chose que je n'utilise généralement pas
          dans mon métier habituel ! 🤷‍♂️
        </p>
        <br />
        <p className="text-base md:text-xl text-justify text-gray-800 font-glacial">
          Si jamais les technologies dans lesquelles je l'ai écrit t'intéressent
          : c'est fait en <b>TypeScript</b>, <b>ReactJS</b> et{" "}
          <b>TailwindCSS</b>. Il est déployé et hébergé sur <b>Vercel</b> avec{" "}
          <b>GitHub Actions</b>!
        </p>
        <br />
      </div>

      <div className="pt-5 pb-16 text-justify">
        <div className="pt-5">
          <SubTitle text="Que puis-je faire pour toi ?" />
        </div>
        <p className="pt-16 text-base md:text-xl text-justify text-gray-800 font-glacial">
          J'imagine que tu es là parce que tu cherches des compétences de
          Backend, et que tu hésites entre faire appel à un Freelance ou l'ESN ? Tu aimerais
          recruter quelqu'un en interne mais les profils, expérimentés et
          disponibles, ne courent pas les rues ?!
        </p>
        <br />
        <p className="text-base md:text-xl text-justify text-gray-800 font-glacial">
          Eh bien je t'invite à parcourir un peu mon site web, j'y détaille mes
          expériences & mon savoir-faire. Les pages{" "}
          <a href="/about">A propos</a> ou encore{" "}
          <a href="/services">Travailler avec moi</a> te donneront un aperçu de
          ce que je peux faire pour toi !
        </p>
        <br />
        <p className="text-base md:text-xl text-justify text-gray-800 font-glacial">
          Il est prévu que je poste ici mes divers projets personnels sur plein
          de nouvelles techniques et technologies dans une page qui est en
          construction... En attendant tu peux me retrouver ou prendre contact
          avec moi sur{" "}
          <a
            className="underline hover:font-bold"
            href="https://www.linkedin.com/in/ramplou-denis-32024a68/"
          >
            LinkedIn
          </a>
          ,{" "}
          <a
            className="underline hover:font-bold"
            href="https://app.comet.co/freelancer/profile/BDbDOzAal2?params=eyJhbm9ueW1pemUiOmZhbHNlLCJkZXNpZ25Nb2RlIjpmYWxzZSwicmVhZE9ubHkiOnRydWV9"
          >
            Comet.co
          </a>
          ,{" "}
          <a
            className="underline hover:font-bold"
            href="https://github.com/D0ud0v"
          >
            GitHub
          </a>{" "}
          ou directement via la{" "}
          <a className="underline hover:font-bold" href="/contact">
            page de contacts
          </a>
          .
        </p>
        <br />
      </div>
      <div className="pb-32 text-center">
        <SubTitle text="Bonne visite, et à très bientôt !" />
      </div>
    </div>
  );
};
