import "./App.css";
//Views
import { Home } from "./views/Home";
import { Contact } from "./views/Contact";
import { About } from "./views/About";
import { Legal } from "./views/LegalInformation";
import { Services } from "./views/Services";
import { Privacy } from "./views/Privacy";
import { NotFound } from "./views/NotFound";
//Routing
import { Routes, Route } from "react-router-dom";
//Base Components
import { HeaderComponent } from "./components/HeaderComponent";
import { FooterComponent } from "./components/FooterComponent";
import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async'; 

function App() {
  const helmetContext = {};

  return (
    <div>
      <HelmetProvider context={helmetContext}>
      <HeaderComponent />
      <Helmet>
        <title>Denis Ramplou 💻 Développeur Backend freelance [API]</title>
        <link rel="canonical" href="https://www.denis-ramplou.fr/" />
        <link rel="shortlink" href="https://denis-ramplou.fr/" />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="description"
          content="Tu as un besoin ? Je mets mes compétences au service de tes ambitions via un accompagnement complet sur des API existantes et futures, du REST ou du GraphQL, dans le Cloud ou sur serveur interne !"
        />
      </Helmet>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/legal-informations" element={<Legal />} />
        <Route path="/services" element={<Services />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/404" element={<NotFound />} />
      </Routes>
      <FooterComponent />
      </HelmetProvider>
    </div>
  );
}

export default App;
